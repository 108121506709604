const disabilityLevel = [
    {
        name: '一级伤残',
        id: 1,
        disabilityAllowance: 0.9,
        disabilitySubsidy: 27,
        coefficient: 1,
    },
    {
        name: '二级伤残',
        id: 2,
        disabilityAllowance: 0.85,
        disabilitySubsidy: 25,
        coefficient: 0.9,
    },
    {
        name: '三级伤残',
        id: 3,
        disabilityAllowance: 0.8,
        disabilitySubsidy: 23,
        coefficient: 0.8,
    },
    {
        name: '四级伤残',
        id: 4,
        disabilityAllowance: 0.75,
        disabilitySubsidy: 21,
        coefficient: 0.7,
    },
    {
        name: '五级伤残',
        id: 5,
        disabilityAllowance: 0.7,
        disabilitySubsidy: 18,
        coefficient: 0.6,
    },
    {
        name: '六级伤残',
        id: 6,
        disabilityAllowance: 0.6,
        disabilitySubsidy: 16,
        coefficient: 0.5,
    },
    {
        name: '七级伤残',
        id: 7,
        disabilityAllowance: 0,
        disabilitySubsidy: 13,
        coefficient: 0.4,
    },
    {
        name: '八级伤残',
        id: 8,
        disabilityAllowance: 0,
        disabilitySubsidy: 11,
        coefficient: 0.3,
    },
    {
        name: '九级伤残',
        id: 9,
        disabilityAllowance: 0,
        disabilitySubsidy: 9,
        coefficient: 0.2,
    },
    {
        name: '十级伤残',
        id: 10,
        disabilityAllowance: 0,
        disabilitySubsidy: 7,
        coefficient: 0.1,
    },
]

export { disabilityLevel }
