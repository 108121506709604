<template>
    <div>
        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <div v-for="item in workInjuryData" :key="item">
                <CalculatorSelect
                    :form="form"
                    :label="item.workInjuryDict.param"
                    :data="item.details"
                    v-if="item.workInjuryDict.type == 2"
                    :font="item.workInjuryDict.paramName"
                    @handleChange="handleChange"
                ></CalculatorSelect>
                <CalculatorInputNumber
                    :form="form"
                    :label="item.workInjuryDict.param"
                    :suffix="item.workInjuryDict.unit"
                    :font="item.workInjuryDict.paramName"
                    :maxLength="item.workInjuryDict.maxLength"
                    v-if="item.workInjuryDict.type == 1"
                ></CalculatorInputNumber>
            </div>
            <!-- 
            <CalculatorInputNumber
                :form="form"
                label="salary"
                suffix="元"
                font="月平均工资"
                :maxLength="10"
            ></CalculatorInputNumber>
            <CalculatorInputNumber
                :form="form"
                label="hospitalization"
                suffix="天"
                font="住院天数"
                :maxLength="5"
            ></CalculatorInputNumber>
            <CalculatorInputNumber
                :form="form"
                label="downtimePaymentPeriod"
                suffix="月"
                font="停工留薪期"
                :maxLength="3"
            ></CalculatorInputNumber>
            <CalculatorInputNumber
                :form="form"
                label="medicalFees"
                suffix="元"
                font="医疗费"
                :maxLength="10"
            ></CalculatorInputNumber>
            <CalculatorInputNumber
                :form="form"
                label="auxiliaryInstrumentFee"
                suffix="元"
                font="辅助器具费"
                :maxLength="10"
            ></CalculatorInputNumber> -->
        </a-form-model>
        <ButtonView @start="start" @reset="reset"></ButtonView>
        <Info :mark="mark"></Info>
        <CalculatorResultClassification :result="result"></CalculatorResultClassification>
    </div>
</template>

<script>
    import CalculatorSelect from '@/components/PC/calculator/calculator-select.vue'
    import CalculatorInputNumber from '@/components/PC/calculator/calculator-inputNumber.vue'
    import ButtonView from '@/components/PC/calculator/button-view.vue'
    import CalculatorResultClassification from '@/components/PC/calculator/calculator-result-classification.vue'
    import Info from '@/components/PC/calculator/info.vue'
    import { disabilityLevel } from '../data/disabilityLevel'
    import { intervalCalculation } from './intervalCalculation'
    import vue from 'vue'
    import * as Server from '@/api/server.js'
    import { apiList } from '@/api/apiList'

    export default {
        name: 'injuryOnTheJob',
        components: {
            CalculatorSelect,
            ButtonView,
            Info,
            CalculatorInputNumber,
            CalculatorResultClassification,
        },
        data() {
            return {
                labelCol: { span: 5 },
                wrapperCol: { span: 17 },
                disabilityLevel,
                result: [],
                mark: '',
                workInjuryData: [],
                formCopy: {},
                form: {},
            }
        },
        mounted() {
            this.getWorkInjuryDict()
        },
        methods: {
            handleChange(obj) {
                // this.$set(this.form, [obj.label], obj.value)
                this.form[obj.label] = obj.value
                let form = JSON.parse(JSON.stringify(this.form))
                this.form = {}
                this.form = JSON.parse(JSON.stringify(form))
                // this.form = '5555'
            },
            async getWorkInjuryDict() {
                let data = {
                    method: 'POST',
                    url: apiList.workInjuryDict,
                    config: {
                        data: {},
                    },
                    loading: true,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                let form = {}
                dataSource.data.forEach((el) => {
                    if (el.workInjuryDict.type == '1' || el.workInjuryDict.type == '2') {
                        Object.assign(form, {
                            [el.workInjuryDict.param]:
                                el.workInjuryDict.type == '1' ? '' : el.details[0].flag,
                        })
                    }

                    if (el.details.length > 0) {
                        el.details.forEach((item) => {
                            item.name = item.description
                            item.id = item.flag
                        })
                    }
                    if (el.workInjuryDict.type == '0') {
                        this.mark = el.details[0].description
                    }
                })
                this.form = form
                this.formCopy = JSON.parse(JSON.stringify(this.form))
                this.workInjuryData = dataSource.data
            },
            async start(callback) {
                for (let item in this.form) {
                    if (!this.form[item]) {
                        this.form[item] = 0
                    }
                }
                let sendData = {}
                for (let item in this.form) {
                    sendData[item] = this.form[item]
                    if (!sendData[item]) {
                        sendData[item] = '0'
                    }
                }

                let data = {
                    method: 'POST',
                    url: apiList.calculate + '/3',
                    config: {
                        data: sendData,
                    },
                    loading: true,
                }
                let dataSource = await Server.Axios(data)
                if (dataSource.data.returnCode == 500) {
                    this.$message.error('服务出错，请稍后重试')
                    return
                }
                dataSource.data.returnData.results.forEach((el) => {
                    el.name = el.label
                    el.money = el.res
                })
                this.result = dataSource.data.returnData.results
                callback()
            },

            reset() {
                this.form = JSON.parse(JSON.stringify(this.formCopy))
                this.result = []
                this.result.splice(0, 0)
            },
        },
    }
</script>
<style scoped lang="less">
    /deep/.ant-form-item-label label {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
    }
</style>
